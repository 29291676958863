import moment from 'moment'
import idb from 'idb'

export default class Database {
  // private
  #table = 'shake-it'
  #version = 1

  static get name () {
    return 'sec-10'
  }

  async write (count) {
    const highScore = await this.#highScore(count)

    const db = await idb.open(Database.name, this.#version, (upgradeDB) => this.#create(upgradeDB))

    const transaction = await db.transaction(this.#table, 'readwrite')
    const store = transaction.objectStore(this.#table)

    await store.add({
      'count': count,
      'timestamp': moment().format('YYYY-MM-DD HH:mm:ss')
    })

    const res = await transaction.complete
      .then((e) => {
        return {
          success: true,
          highScore: highScore.success ? highScore.high : false
        }
      })
      .catch((e) => {
        return {
          success: false,
          message: e
        }
      })

    db.close()
    return res
  }

  // 上位3位
  async highOrder () {
    const id = '#high-order'
    const db = await idb.open(Database.name, this.#version, (upgradeDB) => this.#create(upgradeDB))

    const transaction = await db.transaction(this.#table, 'readonly')

    const data = await transaction.objectStore(this.#table).getAll()
    data.sort(this.#compareDesc)

    const list = data.slice(0, 3)
    let html = ''

    list.forEach((item, index) => {
      html += `<li>${index + 1}位： ${item.count}回 (${moment(item.timestamp).format('YYYY年MM月DD日 HH:mm:ss')})</li>`
    })

    const res = await transaction.complete
      .then((e) => {
        return {
          success: true,
          id,
          html
        }
      })
      .catch((e) => {
        return {
          success: false,
          id
        }
      })

    db.close()
    return res
  }

  async allList (order) {
    const id = '#archive'
    const db = await idb.open(Database.name, this.#version, (upgradeDB) => this.#create(upgradeDB))

    const transaction = await db.transaction(this.#table, 'readonly')
    const data = await transaction.objectStore(this.#table).getAll()

    // 降順
    if (order === 'prev') data.reverse()

    let html = ''

    data.forEach((item) => {
      html += `<li>${item.count}回 (${moment(item.timestamp).format('YYYY年MM月DD日 HH:mm:ss')})</li>`
    })

    const res = await transaction.complete
      .then((e) => {
        return {
          success: true,
          id,
          html
        }
      })
      .catch((e) => {
        return {
          success: false,
          id
        }
      })

    db.close()
    return res
  }

  #create = (upgradeDB) => {
    const store = upgradeDB.createObjectStore(this.#table, { autoIncrement: true })

    store.createIndex('count', 'count', { unique: false })
    store.createIndex('timestamp', 'timestamp', { unique: false })
  }

  #highScore = async (count) => {
    const db = await idb.open(Database.name, this.#version, (upgradeDB) => this.#create(upgradeDB))

    const transaction = await db.transaction(this.#table, 'readonly')
    const data = await transaction.objectStore(this.#table).getAll()
    data.sort(this.#compareDesc)

    const res = await transaction.complete
      .then((e) => {
        return {
          success: true,
          high: data.length === 0 ? true : count > data[0].count
        }
      })
      .catch((e) => {
        console.log(e)
        return {
          success: false
        }
      })

    db.close()
    return res
  }

  /**
   * 降順
   * ※同一の場合は日付が若いほう優先
   * @param a
   * @param b
   * @return {number}
   */
  #compareDesc = (a, b) => {
    const { count: countA, timestamp: _timeA } = a
    const { count: countB, timestamp: _timeB } = b

    const timeA = moment(_timeA).format('x')
    const timeB = moment(_timeB).format('x')

    let compare = 0

    if (countA > countB) {
      compare = 1
    } else if (countA <= countB && timeA < timeB) {
      compare = -1
    }

    return compare * -1
  }
}