import Database from './modules/Database'
import Shake from './modules/Shake'
import Worker from './worker/databese.worker'

const shake = new Shake()

$('#ready-btn').on('click', () => {
  shake.start()
})

$('#reset-button').on('click', () => {
  indexedDB.deleteDatabase(Database.name)
  location.reload()
})

const w = new Worker()
w.addEventListener('message', (e) => {
  const data = e.data[0]
  if (data.success) {
    if (data.html !== '') {
      $(`${data.id}-list`).removeClass('hide').html(data.html)
      $(`${data.id}-none`).addClass('hide')
    } else {
      $(`${data.id}-list`).addClass('hide').html('')
      $(`${data.id}-none`).removeClass('hide')
    }
  } else {
    $(`${data.id}-none`).addClass('hide')
    $(`${data.id}-list`).removeClass('hide').html('<li>エラー</li>')
  }
}, false)

$('#high-order-button').on('click', () => {
  w.postMessage({
    func: 'highOrder'
  })
})

$('.js-archive-button').on('click', (e) => {
  w.postMessage({
    func: 'allList',
    order: $(e.currentTarget).data('order')
  })
})