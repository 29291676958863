import animejs from 'animejs'
import Worker from '../worker/databese.worker'

export default class Shake {
  #count = 0
  #db = new Worker()

  constructor () {
    this.#count = 0
    this._shake = $('#result-shake')

    const platform = navigator.platform.toLowerCase()
    this._iOS = /ip(hone|ad|od)/.test(platform)

    this.#db.addEventListener('message', (e) => {
      const data = e.data[0]

      if (data.success) {
        console.log('count write success', `新記録:${data.highScore}`)

        if (data.highScore) {
          $('#result-high-score').removeClass('hide')
        }

      } else {
        console.log('count write error')
      }
    }, false)
  }

  start () {
    $('#ready').hide()
    $('#count-down').show()

    this._countDown()
  }

  _reset () {
    $('#count-down, #progress, #result').hide()
    $('#count-down-text, #progress-text, #result-text').text('')
    $('#result-high-score').addClass('hide')
    this.#count = 0
  }

  _countDown () {
    const text = $('#count-down-text')
    const sec = 3

    animejs({
      targets: '#count-down-text',
      duration: sec * 1000,
      update: (anim) => {
        const num = Math.abs(sec - Math.floor(anim.currentTime / 1000))
        text.text(num)
      },
      complete: (anim) => {
        if (anim.completed) {
          $('#count-down').hide()
          this._monitoring()
        }
      }
    })
  }

  _monitoring () {
    $('#progress').show()

    const text = $('#progress-count')
    const sec = 10

    // モーションイベント
    const motion = (e) => {
      // console.log('dev2', e)
      let y = e.acceleration.y

      if (this._iOS) {
        y *= -1
      }

      if (Math.floor(y) > 0) {
        this.#count += 1
      }
    }

    window.addEventListener('devicemotion', motion, false)

    animejs({
      targets: '#progress-count',
      duration: sec * 1000,
      update: (anim) => {
        const num = Math.abs(sec - Math.floor(anim.currentTime / 1000))
        text.text(num)
      },
      complete: (anim) => {
        if (anim.completed) {
          this.#db.postMessage({
            'func': 'write',
            count: this.#count,
            order: 'next'
          })

          $('#progress').hide()
          $('#result').show()
          window.removeEventListener('devicemotion', motion, false)

          $('#result-btn').one('click', () => {
            this._reset()
            this.start()
          })
          $('#result-text').text(`finish: ${this.#count}`)
        }
      }
    })
  }
}